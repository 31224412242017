<template>
  <ion-page>
    <ion-content style="--background: var(--ion-color-light)">
      <ion-header>
        <ion-toolbar>
          <ion-title>Medical Certificate</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-card class="main-card">
        <ion-card-header>
          <h1 style="margin-bottom:0px">Medical Certificate</h1>
          <p style="color:#BF0000;font-weight: 600;">{{ user.first_name }} {{ user.last_name }}</p>
        </ion-card-header>

        <ion-card-content>
          <span class="p-float-label">
            <InputText id="dx" type="text" disabled v-model="scriptData.dx" />
            <label for="dx">Dx (Diagnosis)</label>
          </span>

          <span class="p-float-label">
            <Textarea
              :autoResize="true"
              rows="5"
              cols="30"
              style="min-height: 200px"
              disabled
              v-model="scriptData.rx"
            />
            <label for="rx">Rx (Treatment)</label>
          </span>

          <p style="margin-bottom: 6px">This medical certificate states that</p>
          <p style="margin-bottom: 6px">
            <strong style="text-transform: uppercase"
              >{{ user.first_name }} {{ user.last_name }}</strong
            >
          </p>
          <p style="margin-bottom: 6px">had a consult on</p>
          <p style="margin-bottom: 6px">
            <strong>{{ scriptData.consult_date }}</strong>
          </p>

          <p v-if="scriptData.end_date" style="margin-bottom: 6px">
            This medical certificate provided is proof of this consult and
            states that the patient is unfit for duty on the day of
          </p>
          <p style="margin-bottom: 6px">
            <strong>{{ scriptData.start_date }}</strong>
          </p>
          <p style="margin-bottom: 6px">and will return to duty on the</p>
          <p>
            <strong>{{ scriptData.end_date }}</strong>
          </p>

          <span class="p-float-label" v-if="scriptData.referral">
            <Textarea
              :autoResize="true"
              rows="5"
              cols="30"
              style="min-height: 200px"
              disabled
              v-model="scriptData.referral"
            />
            <label for="referral">Referral</label>
          </span>

          <span class="p-float-label" v-if="scriptData.disposition">
            <Textarea
              :autoResize="true"
              rows="5"
              cols="30"
              style="min-height: 200px"
              disabled
              v-model="scriptData.disposition"
            />
            <label for="disposition">Disposition</label>
          </span>

          <span class="p-float-label" style="margin-top: 29px">
            <InputText
              id="medical_certificate_comments"
              type="text"
              disabled
              v-model="scriptData.medical_certificate_comments"
            />
            <label for="medical_certificate_comments"
              >Additional Comments</label
            >
          </span>

          <div id="agree">
            <a class="unselected router-button" style="margin-right:48px" @click.prevent="agree()">I do not Agree</a>
            <a class="router-button" @click.prevent="agree()">I Agree</a>
          </div>

          <hr />
          <ion-row>
            <ion-col size-md="10" size-lg="10" style="text-align: left">
              <!-- <img v-bind:src="doctorProfile.signature" height="35"> -->
              <p style="font-size: 18px; line-height: 27px">
                <strong>Dr. {{ initials }}</strong>
              </p>
              <p>
                <!-- <span>{{ doctorProfile.degree }}</span> -->
              </p>
              <p><span>MP0747122</span></p>
              <p><span>{{doctor.email}}</span></p>
            </ion-col>
            <ion-col size-md="2" size-lg="2">
              <img
                src="/assets/icon/WeDoc_Logo_Standalone_Portrait.svg"
                height="60"
              />
            </ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  alertController,
} from "@ionic/vue";

export default {
  name: "Medical Certificate",
  components: {
    IonCardHeader,
    IonCardContent,
    IonCard,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonPage,
    IonContent
  },
  data() {
    return {
      user_id: "",
      email: "",
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    doctor() {
      return this.$store.state.consults.consult.doctor;
    },
    doctorProfile() {
      return this.$store.state.consults.consult.doctor_profile;
    },
    initials() {
      if(this.doctor){
        return this.doctor.first_name.charAt(0) + " " + this.doctor.last_name;
      }else{
        return "";
      }      
    },
    scriptData() {
      if (this.$store.state.consults.consult.script) {
        return this.$store.state.consults.consult.script;
      } else {
        return [];
      }
    },
  },
  ionViewDidEnter() {
    console.log(this.doctorProfile);
    this.email = this.user.email;
    this.user_id = this.user.id;
    this.$store.dispatch("GetConsult", this.user.id);
  },
  methods: {
    async agree() {
      const alert = await alertController.create({
        message:
          `<p style="font-size: 11px;line-height: 16.5px;text-align: center;color: #343434;margin: 0px" >If you are satisfied with your consult, diagnosis, and prescription, click ‘I Agree’ below and an email will be sent to <strong>${this.user.email}</strong>. This acknowledges that you agree with advice provided by the doctor, have been informed by the doctor how to administer medication if prescribed and when to seek further medical assistance,</p>`,
        buttons: [
          {
            text: "I do not Agree",
            role: "cancel",
            handler: () => {
              console.log('cancel');
            }
          }, 
          {
            text: "I Agree",
            handler: () => {
              console.log('agree');
              // Email script && broadcast
              this.$store.dispatch("AcceptScript", this.user.id);
              document.getElementById('agree').style.display = "none";
            }
          } 
        ],
      });

      await alert.present();
    },
  },
  mounted() {
    window.Echo.channel(this.user.id);
    window.Echo.connector.socket.on("App\\Events\\ConsultEnded", (data) => {
      console.log(data);
      this.$router.push("/member/dashboard");
    });
  }
};
</script>

<style scoped>
ion-card-title {
  color: var(--ion-color-primary);
}

.main-card {
  margin-top: 12px;
}

p {
  font-size: 16px;
  line-height: 24px;
}


.option {
  border: 1px solid #c7c7c7;
  height: 73px;
  width: 25%;
  border-radius: 7px;
  margin-right: 23px;
  float: left;
}

.selected {
  border: 2px solid var(--ion-color-primary);
}

form {
  padding-top: 102px;
}

h1 {
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  color: #000000;
  margin-top: 34px;
  margin-bottom: 35px;
}

.p-float-label {
  margin-bottom: 27px;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #000000;
}

.hr-vertical {
  border: 0;
  border-top: 1px solid #000000;
  transform: rotate(90deg);
  position: absolute;
  width: 87px;
  height: 0px;
  top: 28px;
  left: -46px;
}

/* #alert-1-msg {
  font-size: 11px;
  text-align: center;
  color: #343434;
} */
</style>